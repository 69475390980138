<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1100px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
            <v-radio-group v-model="besoin" row mandatory dense>
              <v-radio label="Besoins" :value="true"></v-radio>
              <v-radio label="Produits" :value="false"></v-radio>
            </v-radio-group>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="8" v-if="besoin">
                  <v-autocomplete
                    v-model="editedItem.dem_det_id"
                    :item-value="'id'"
                    :items="products_list"
                    :filter="customFilter"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                    @change="product_change"
                    :loading="loading"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        item.no_da +
                        "-" +
                        (item.code ? item.code + "-" : "") +
                        item.label
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        item.no_da +
                        "-" +
                        (item.code ? item.code + "-" : "") +
                        item.label +
                        "-" +
                        item.qte_reste +
                        " " +
                        item.unit_name
                      }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="8" v-if="!besoin">
                  <cursorselect
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="editedItem.produit_id"
                    :text_fields="['code', 'label']"
                    :variableadd="variableadd"
                    @change="product_change"
                    :label="'Produit'"
                    :key="cs"
                  >
                  </cursorselect>
                  <!-- <v-autocomplete
                    v-model="editedItem.produit_id"
                    :item-value="'id'"
                    :items="products_list"
                    :filter="customFilter"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                    @change="product_change"
                    :loading="loading"
                  >
                    <template v-slot:selection="{ item }">
                  
                      {{ (item.code ? item.code + "-" : "") + item.label }}
                    </template>
                    <template v-slot:item="{ item }">
                   
                      {{ (item.code ? item.code + "-" : "") + item.label }}
                    </template>
                  </v-autocomplete> -->
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="qte"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model="editedItem.qte"
                    :readonly="readonly"
                    :label="
                      editedItem.unit_name ? editedItem.unit_name : 'Quantité'
                    "
                    :rules="[
                      (v) =>
                        !!v ||
                        (editedItem.unit_name
                          ? editedItem.unit_name
                          : 'Quantité') + ' obligatoire',
                      (v) => !v || v > 0 || 'Quantité incorrecte',
                      (v) =>
                        !v ||
                        !besoin ||
                        parseFloat(v) <= parseFloat(max_qte) ||
                        'Quantité Max ' +
                          max_qte +
                          (editedItem.unit ? ' ' + editedItem.unit : ''),
                    ]"
                    @keydown.enter="save"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="10">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_CONSULTATION_DETAIL from "../graphql/Consult/CREATE_CONSULTATION_DETAIL.gql";
import UPDATE_CONSULTATION_DETAIL from "../graphql/Consult/UPDATE_CONSULTATION_DETAIL.gql";
import PRODUCTS_CURSOR from "../graphql/Commande/PRODUCTS_CURSOR.gql";

export default {
  components: {
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "consultdetailform",
  props: {
    item: Object,
    items: Array,
    dem_products: Array,
    readonly: Boolean,
    showForm: Boolean,
  },

  data: () => ({
    besoin: true,
    duedate: null,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    combovalue: "",
    isProductClosed: true,
    template: 0,
    attributs: [],
    unfilter: false,
    pageInfo: null,
    label: null,
    cs: 0,
    label_ext: "",
    ref_ext: "",
    loading: false,
    max_qte: 0,
  }),

  computed: {
    products_cursor() {
      return PRODUCTS_CURSOR;
    },
    variableadd() {
      let w = {
        Type: this.$store.state.products_types.map((elm) => elm.id),
        Kind: 2,
        statut_id: [2],
      };
      return w;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
    products_list() {
      let l = [];
      if (this.besoin) l = this.dem_products;

      if (l)
        l = l.filter(
          (elm) =>
            !this.items.map((elm3) => elm3.produit_id).includes(elm.id) ||
            elm.id == this.editedItem.produit_id
        );

      return l;
    },
  },
  watch: {},

  created() {},
  async mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      if (this.editedItem.id > 0) {
        this.cs++;
        this.besoin = this.editedItem.dem_det_id > 0;
        this.product_change();
      }
    }
  },

  methods: {
    product_change(item, l) {
      let list = this.products_list;
      if (l) {
        list = l;
        this.editedItem.produit_id = item;
      }
      let i = list.findIndex(
        (elm) =>
          elm.id ==
          (this.besoin
            ? this.editedItem.dem_det_id
            : this.editedItem.produit_id)
      );
      this.max_qte = 0;
      if (i >= 0) {
        if (this.besoin) {
          if (this.editedItem.id < 0) {
            this.editedItem.qte = list[i].qte_reste;
          }
          this.editedItem.produit_id = list[i].produit_id;
          this.max_qte =
            list[i].qte_reste + (this.editedItem.qte ? this.editedItem.qte : 0);
        }
        this.editedItem.articles = list[i].articles;
        this.editedItem.unit_name = list[i].unit_name;
      }
    },
    customFilter(item, queryText) {
      let textlist = [];
      ["no_da", "code", "label"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });
      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.$store.dispatch("Changed", true);
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (!this.besoin) this.editedItem.dem_det_id = null;
        let v;
        if (this.editedItem.id > 0) {
          v = {
            consultationdetail: {
              id: this.editedItem.id,
              qte: parseFloat(this.editedItem.qte),
              dem_det_id: this.editedItem.dem_det_id,
              produit_id: this.editedItem.produit_id,
              comment: this.editedItem.comment,
              write_uid: this.$store.state.me.id,
            },
          };
          this.maj(UPDATE_CONSULTATION_DETAIL, v);
        } else {
          let list = [];
          list.push({
            qte: parseFloat(this.editedItem.qte),
            dem_det_id: this.editedItem.dem_det_id,
            produit_id: this.editedItem.produit_id,
            cons_id: this.item.cons_id,
            comment: this.editedItem.comment,
            create_uid: this.$store.state.me.id,
            write_uid: this.$store.state.me.id,
          });
          v = {
            consultationdetail: list,
          };
          await this.maj(CREATE_CONSULTATION_DETAIL, v);
        }
        this.close();
      }
    },
  },
};
</script>
